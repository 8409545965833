<template>
    <div>
        <el-card class="management-report-card-box">
            <el-form>
                <div class="flex flex-row justify-between items-center mb-2">
                    <h2 class="text-base font-semibold mb-2">{{ title }}</h2>
                    <div class="flex items-center">
                        <el-switch v-model="localToggleValue" active-color="#48c78e" inactive-color="#D1D5DB" @change="onToggleChange" />
                    </div>
                </div>
                <div v-if="toggleValue" v-loading="$waiting.is('loading-signatureInfo')" class="flex flex-col">
                    <div class="flex flex-col pl-2">
                        <el-checkbox v-model="localData.addTitle" :disabled="inputDisabled">Lägg till rubrik Revisorspåteckning</el-checkbox>
                        <el-checkbox v-model="localData.auditReport.add" :disabled="inputDisabled">Ange revisionsberättelse</el-checkbox>
                        <div v-if="localData.auditReport.add">
                            <el-checkbox v-model="localData.auditReport.electronicSignatureReferences.add" :disabled="inputDisabled">
                                Revisionsberättelse hänvisning till elektronisk underskrift
                            </el-checkbox>
                            <div v-if="localData.auditReport.electronicSignatureReferences.add">
                                <h2 class="text-sm font-semibold mb-2 mt-4">Revisionsberättelse elektronisk underskrift</h2>
                                <el-input type="text" :disabled="inputDisabled" v-model="localData.auditReport.electronicSignatureReferences.text" />
                            </div>
                            <div v-else class="flex flex-row space-x-4">
                                <div>
                                    <h2 class="text-sm font-semibold mb-2 mt-4">Revisionsberättelse underskrift</h2>
                                    <el-input type="text" :disabled="inputDisabled" v-model="localData.auditReport.text" style="width:400px;" />
                                </div>
                                <div>
                                    <h2 class="text-sm font-semibold mb-2 mt-4">Datum</h2>
                                    <div class="flex items-center">
                                        <el-radio-group v-model="dateType" size="small" :disabled="inputDisabled" class="pr-2">
                                            <el-radio-button label="År" />
                                            <el-radio-button label="Dag" />
                                        </el-radio-group>
                                        <el-date-picker
                                            v-show="dateType == 'År'"
                                            type="year"
                                            :disabled="inputDisabled"
                                            v-model="localData.auditReport.date"
                                            style="width:150px;"
                                            placeholder="Välj år"
                                        />
                                        <el-date-picker v-show="dateType == 'Dag'" :disabled="inputDisabled" v-model="localData.auditReport.date" style="width:150px;" placeholder="Välj dag" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width: fit-content;">
                        <h2 class="text-sm font-semibold mb-2 mt-8 pl-2">Revisorer</h2>
                        <el-table :data="localData.auditPersons">
                            <el-table-column label="Förnamn" width="200">
                                <template slot-scope="{ row }">
                                    <el-input v-model="row.firstName" :disabled="inputDisabled" />
                                </template>
                            </el-table-column>
                            <el-table-column label="Efternamn" width="200">
                                <template slot-scope="{ row }">
                                    <el-input v-model="row.lastName" :disabled="inputDisabled" />
                                </template>
                            </el-table-column>
                            <el-table-column label="Titel" width="150">
                                <template slot-scope="{ row }">
                                    <el-input v-model="row.title" :disabled="inputDisabled" />
                                </template>
                            </el-table-column>
                            <el-table-column label="Byrå" width="150">
                                <template slot-scope="{ row }">
                                    <el-input v-model="row.companyName" :disabled="inputDisabled" />
                                </template>
                            </el-table-column>
                            <el-table-column width="120">
                                <template slot-scope="{ $index, store, row }">
                                    <template v-if="store.states.data.length !== 1">
                                        <el-button plain size="mini" @click="deleteRowTable(row)" :disabled="inputDisabled">
                                            <i class="fa-solid fa-minus" />
                                        </el-button>
                                    </template>
                                    <template v-if="$index === store.states.data.length - 1">
                                        <el-button plain size="mini" @click="addRowTable" :disabled="inputDisabled">
                                            <i class="fa-solid fa-plus" />
                                        </el-button>
                                    </template>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </el-form>
            <CardEditButtons :toggle-value="toggleValue" :show-edit-button="showEditButton" :toggle-edit="toggleEdit" :close-modal="closeModal" :save-data="saveData" />
        </el-card>
    </div>
</template>
<script>
import { Switch } from "element-ui";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        tooltipInfo: {
            type: String,
            default: "",
        },
        toggleValue: {
            type: Boolean,
            default: true,
        },
        data: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            selectedYear: null,
            financialYearId: null,
            annualReportId: undefined,
            inputDisabled: true,
            showEditButton: true,
            localToggleValue: this.toggleValue,
            localData: this.data,
            initialInputText: "",
            isInitialInputTextSet: false,
            dateType: "År",
        };
    },
    components: {
        "el-switch": Switch,
        CardEditButtons: () => import("../../managementStatement/components/CardEditButtons.vue"),
    },
    watch: {
        toggleValue(newVal) {
            this.localToggleValue = newVal;
        },
    },
    methods: {
        toggleEdit() {
            this.inputDisabled = !this.inputDisabled;
            this.showEditButton = !this.showEditButton;
        },
        onToggleChange(newVal) {
            const eventData = {
                source: 2,
                value: newVal,
            };
            this.$emit("toggle-change", eventData);
        },
        async saveData() {
            this.data = this.localData;
            this.data.auditReport.isYearDate = this.dateType === "År";
            this.isInitialInputTextSet = false;
            this.$emit("saveData");
            this.toggleEdit();
            this.$notify.success({ title: `Sparat "${this.title}"` });
        },
        closeModal() {
            this.$emit("close");
            const load = "loading-signatureInfo";
            this.$emit("close", { load: load, inputTextNewValue: this.inputTextNewValue });
            this.toggleEdit();
        },
        addRowTable() {
            const newObj = {
                firstName: "",
                lastName: "",
                title: "",
                companyName: "",
            };
            this.localData.auditPersons.push(newObj);
        },
        deleteRowTable(row) {
            const auditPersonRows = this.localData.auditPersons;
            if (auditPersonRows.length > 0) {
                const index = auditPersonRows.indexOf(row);
                if (index > -1) {
                    auditPersonRows.splice(index, 1);
                }
            }
        },
    },
};
</script>
